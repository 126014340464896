import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStoreon } from 'storeon/react'
import { ApiContext, useAuth } from '@hb/web-hooks'

const {
  H4B_API_SECURE: API_SECURE,
  H4B_API_URL: API_URL,
  H4B_API_URL_SANDBOX: API_URL_SANDBOX,
  H4B_API_PORT: API_PORT,
  H4B_API_MOUNT: API_MOUNT,
} = h4benv

const ApiProvider = ({ isSandboxMode, children }) => {
  const { dispatch, user } = useStoreon('user')
  const { verifyUser } = useAuth()

  const retryAfterUnauthorized = useCallback(async () => {
    if (!user.refreshToken) {
      // this is kinda bad
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
      throw new Error('No refresh token available')
    }

    const response = await verifyUser({
      id_token: user.idToken,
      refresh_token: user.refreshToken,
      access_token: user.accessToken,
    })

    dispatch('user/set', {
      ...response.tokens,
      authUser: {
        ...response.user,
      },
    })

    return response.tokens.accessToken
  }, [user.idToken, user.refreshToken, verifyUser, dispatch])

  const config = {
    apiSecure: API_SECURE,
    apiUrl: isSandboxMode ? API_URL_SANDBOX : API_URL,
    apiPort: API_PORT,
    apiMount: API_MOUNT,
    onErrorCallback: (r) => {
      if (r.status === 401) {
        console.error('Token has expired mid session')
      }
    },
  }

  return (
    <ApiContext.Provider
      value={{
        token: user.accessToken,
        config,
        debug: true,
        retryAfterUnauthorized,
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isSandboxMode: PropTypes.bool.isRequired,
}

export default ApiProvider
